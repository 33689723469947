import { createApp } from "vue/dist/vue.esm-bundler.js";
import { i18nVue, trans } from 'laravel-vue-i18n'

import Login from "./components/Login.vue";
import PasswordReset from "./components/PasswordReset.vue";
import JobList from "./components/JobList.vue";
import Register from "./components/Register.vue";
import Profile from "./components/Profile.vue";
import php_de from '../../resources/lang/php_de.json';

import './plugins/vee-validate'
import mitt from './plugins/mitt'
window.mitt = mitt

import Swal from 'sweetalert2'
window.Swal = Swal

window.$t = trans


/*import { all } from '@vee-validate/rules';
Object.entries(all).forEach(([name, rule]) => {
  defineRule(name, rule);
});*/

if(document.getElementById('v-login-container')) {
    createApp(Login)
        .provide('$t', trans)
        .use(i18nVue, {
            lang: 'de',
            resolve: lang => php_de,
        })
        .mount('#v-login-container')
}

if(document.getElementById('v-register-container')) {
    createApp(Register)
        .provide('$t', trans)
        .use(i18nVue, {
            lang: 'de',
            resolve: lang => php_de,
        })
        .mount('#v-register-container')
}

if(document.getElementById('v-password-reset')) {
    createApp(PasswordReset)
        .provide('$t', trans)
        .use(i18nVue, {
            lang: 'de',
            resolve: lang => php_de,
        })
        .mount('#v-password-reset')
}

if(document.getElementById('v-list-jobs')) {
    createApp(JobList)
        .provide('$t', trans)
        .use(i18nVue, {
            lang: 'de',
            resolve: lang => php_de,
        })
    	.mount('#v-list-jobs')
}

if(document.getElementById('v-profile')) {
    createApp(Profile)
        .provide('$t', trans)
        .use(i18nVue, {
            lang: 'de',
            resolve: lang => php_de,
        })
        .mount('#v-profile')
}

