<template>
    <!-- liste --> 
    <section class="asymetric-columns block-liste text-box pt-small pb-large" id="content">
        <div class="container px-0 px-md-3">
            <div class="px-3 px-md-0 block-title-filter d-md-block" :class="{'d-none': detailId}">
                <div class="breadcrumbs tincx-small text-muted text-ellipsis">
                    <a class="text-muted text-no-underline" :href="$t('fe.category.job.url')">{{ $t('fe.category.job.title') }}</a><span class="px-1 px-xs-2">/</span
                    ><span class="text-ellipsis">{{ $t('generic.search_results') }}</span>
                </div>
                <h2 class="filter-title tincx-h4 mt-2" v-if="offers">{{ $t('generic.search_results_results', {count: offers.length }) }}</h2>
                <div class="filter-bar d-flex align-items-center justify-content-between">
                    <div class="d-block d-lg-flex filter-desktop">
                        <div class="d-flex d-lg-none align-items-center justify-content-between">
                            <p class="my-0"><strong>{{ $t('generic.filter') }}:</strong></p>

                            <a href="#" class="close-filter p-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                    <path
                                        d="M7.2,6L11.7,1.5c.3-.3.3-.9,0-1.2-.3-.3-.9-.3-1.2,0l-4.5,4.5L1.5.3C1.2,0,.6,0,.3.3,0,.6,0,1.2.3,1.5l4.5,4.5L.3,10.5c-.3.3-.3.9,0,1.2.2.2.4.2.6.2s.4,0,.6-.2l4.5-4.5,4.5,4.5c.2.2.4.2.6.2s.4,0,1.6-.2c.3-.3.3-.9,0-1.2l-4.5-4.5Z"
                                    />
                                </svg>
                            </a>
                        </div>


                        <div class="select-container col-auto mt-2 mt-lg-0 position-relative" v-if="false && 'todo-example-of-multiselect'">
                            <input type="text" name="ausstattung" value="Ausstattung" readonly="" data-default="Ausstattung">
                            <div class="select-box checkboxes bg-white btn btn-ts text-left d-none">
                                <label class="d-flex align-items-center  me-2">
                                    <input type="checkbox" name="ausstattung"><span>Balkon/Terrasse</span>
                                </label>
                                <label class="d-flex align-items-center mt-2 mt-md-1 me-2">
                                    <input type="checkbox" name="ausstattung"><span>Garten</span>
                                </label>
                            </div>
                        </div>

                        <select v-model="filter.category" class="col-auto mt-2 mt-lg-0">
                            <option :value="null">{{ $t('fe.generic.job_category') }}</option>
                            <option v-for="category in categories" :value="category.id">{{ category.name }}</option>
                        </select>

                        <select v-model="filter.employmentType" class="col-auto mt-2 mt-lg-0">
                            <option :value="null" selected>{{ $t('jobs.entry.types') }}</option>
                            <option v-for="employmentType in employmentTypes" :value="employmentType.id">
                                {{ employmentType.name }}
                            </option>
                        </select>

                        <select v-model="filter.benefit" class="col-auto mt-2 mt-lg-0">
                            <option :value="null">{{  $t('fe.generic.benefits') }}</option>
                            <option v-for="benefit in benefits" :value="benefit.id">{{ benefit.name }}</option>
                        </select>

                        <div id="filter-mobile" class="d-lg-none">
                            <div class="d-sm-flex checkbox-filter mt-3 mt-lg-0">
                                <label class="d-flex align-items-center mt-2 mt-sm-0 me-4" v-for="workType in ['full_time', 'part_time', 'remote']">
                                        <input type="checkbox" value="{{  workType }}"><span>{{ $t(`jobs.type.${workType}`) }}</span>
                                </label>
                            </div>
                        </div>

                        <div class="text-center pt-3 mt-0 d-lg-none">
                            <a href="#" class="btn btn-primary btn-large btn-te px-3 search-button">
                                {{ $t('generic.show_results',{count: this.offers ? this.offers.length : 0}) }}
                            </a>
                        </div>
                    </div>

                    <div class="d-flex sorting-map align-items-center">
                        <select v-model="filter.sort" class="col-auto">
                            <option value="new">{{ $t('generic.sort_newest') }}</option>
                            <option value="relevance">{{ $t('generic.sort_relevance')}}</option>
                        </select>
                        <div class="map position-relative d-none d-lg-block" v-if="false && 'todo'">
                            <div class="cover-box">
                                <img src="/images/map.jpg" alt="Map" />
                            </div>
                            <div class="overlay p-2 d-flex align-items-center justify-content-center">
                                <div>
                                    <img class="d-block mx-auto mb-2" src="/images/icons/icon-marker.svg" alt="Marker" />
                                    <a href="#" class="btn btn-primary btn-bs">{{ $t('generic.show_map')}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row-large mx-0 pt-3" id="">
                <div class="sidebar ps-md-0  d-md-flex" :class="{'d-none': detailId}">
                    <template v-for="(offer, index) in offers">
                        <div
                            :data-id="offer.id"
                            @click="selectItem(offer)"
                            :class="{ 'top-offer': offer.offer_of_the_day, active: detailId == offer.id, first: index == 0 }"
                            class="card position-relative"
                        >
                            <template v-if="offer.user_type == 'private'">
                                <span class="job-logo d-flex align-items-center justify-content-center position-absolute text-no-underline">
                                    <img width="16" height="16" src="/images/icons/icon-privatperson.svg" />
                                </span>
                            </template>
                            <template v-else>
                                <a
                                    :href="offer.customer.url"
                                    @click.prevent="selectItem(offer)"
                                    class="job-logo d-flex align-items-center justify-content-center position-absolute text-no-underline"
                                >
                                    <img v-if="offer.customer.logo" :src="offer.customer.logo" :alt="`Logo ${offer.customer.agency}`" />
                                    <strong v-else class="text-muted text-center">{{ offer.customer.agency }}</strong>
                                </a>
                            </template>

                            <span v-if="offer.offer_of_the_day" class="position-absolute badge">{{ $t('fe.category.job.offer_of_the_day') }}</span>
                            <span v-else-if="offer.new" class="position-absolute badge">{{ $t('fe.category.job.new') }}</span>
                            <span v-if="offer.user_type == 'private'" class="tincx-p">{{ $t('fe.category.job.private') }}</span>
                            <span v-else class="tincx-p">{{ offer.customer.agency }}</span>

                            <span class="tincx-h5 d-block my-3 mt-lg-1 mb-lg-3">{{ offer.title }}</span>
                            <div class="d-flex justify-content-between job-info align-items-center mb-2">
                                <span v-if="offer.place" class="d-flex align-items-center tincx-small"
                                    ><img src="/images/icons/icon-location.svg" alt="Location" class="me-1 location-icon" />{{
                                        offer.place
                                    }}</span
                                >
                                <span v-if="offer.art" class="d-flex align-items-center tincx-small"
                                    ><img src="/images/icons/icon-time.svg" alt="Job Art" class="me-1" />{{ offer.art }}</span
                                >
                            </div>
                            <p class="description mt-2" v-html="offer.description"></p>
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="job-time">{{ offer.date }}</span>
                                <span v-if="detailId != offer.id" @click="selectItem(offer)" class="job-more tincx-small">{{ $t('fe.generic.read_more') }}</span>
                            </div>
                        </div>
                    </template>

                    <div class="position-relative" style="order: 3;margin-top: 10px;">
                        <a @click="loadMore" class="btn btn-bs btn-primary" :class="{disabled: loading}">{{ loading ? $t('generic.loading') : $t('generic.load_more') }}</a>
                    </div>

                    <div class="placeholder-item"></div>

                    <div :class="{ 'd-none d-md-block': !detailId }" class="contact-buttons-container" v-if="buttonConfig">
                        <ContactButton :config="buttonConfig" />
                    </div>
                </div>

                <div class="mainbar pe-0 d-md-block" :class="{'d-none': !detailId}">
                    <!-- main-job start -->
                    <img class="d-md-none" src="/images/job/header-jobmarkt-active.jpg" alt="Mobile Header Jobs" />

                    <div class="scroll-box" :class="{'d-none': !detailId}" id="scroll-box">
                        <div class="large-card" ref="largeCard">
                            <div class="row-large">
                                <a
                                    @click.prevent="selectItem(null)"
                                    href="#"
                                    class="d-flex align-items-center tincx-p text-muted mb-3 mt-0 d-md-none close-detail"
                                >
                                    <svg class="me-1" width="15" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.5 15.5">
                                        <path
                                            fill="currentcolor"
                                            d="M7.75,15.5c4.27,0,7.75-3.48,7.75-7.75S12.02,0,7.75,0,0,3.48,0,7.75s3.48,7.75,7.75,7.75ZM7.75,1.29c3.58,0,6.46,2.88,6.46,6.46s-2.88,6.46-6.46,6.46S1.29,11.33,1.29,7.75,4.17,1.29,7.75,1.29Z"
                                        ></path>
                                        <path
                                            fill="currentcolor"
                                            d="M6.46,10.53c.3.3.7.3.89,0s.3-.7,0-.89l-1.29-1.29h4.77c.4,0,.6-.3.6-.6s-.3-.6-.6-.6h-4.77l1.29-1.29c.3-.3.3-.7,0-.89s-.3-.2-.5-.2-.3,0-.5.2l-2.29,2.29h0c-.1.1-.2.3-.2.5s2.58,2.78,2.58,2.78Z"
                                        ></path>
                                    </svg>
                                    {{ $t('generic.back_to_search') }}
                                </a>

                                <!-- start title mobile -->
                                <div id="title-mobile" class="d-lg-none" v-if="selectedItem">
                                    <JobTitleContent :selectedItem="selectedItem" />
                                </div>
                                <!-- end title mobile -->


                                <ul class="tabs d-flex d-lg-none mb-0">
                                    <li class="active">
                                        <a href="#main" @click.prevent="onTab('main')" class="main-content">{{ $t('jobs.job_details') }}</a></li>
                                    <li>
                                        <a href="#info-mobile" @click.prevent="onTab('info-mobile')">
                                            <span class="response-tab-user-info">{{  selectedItem && selectedItem.user_type=='private' ? $t('jobs.the_private') : $t('jobs.the_company') }}</span>
´                                        </a>
                                    </li>
                                </ul>

                                <div class="col-12 col-lg" v-if="selectedItem">
                                    <div id="title-desktop" class="d-none d-lg-block">
                                        <JobTitleContent :selectedItem="selectedItem" />
                                    </div>

                                    <div id="main">
                                        <h3 class="tincx-h5 mt-0 pt-4">{{ $t('jobs.job_description') }}</h3>
                                        <div class="response-description-container">
                                            <p
                                                class="response-description"
                                                v-html="selectedItem.description"
                                                :class="{ hidden: !descriptionReadMore }"
                                            ></p>
                                            <a
                                                href="#"
                                                @click="descriptionReadMore = !descriptionReadMore"
                                                class="text-grey show-more-description"
                                            >
                                                <span class="more" v-if="!descriptionReadMore">{{ $t('generic.read_more') }}</span>
                                                <span class="less" v-else>{{ $t('generic.read_less') }}</span>
                                            </a>
                                        </div>
                                        <hr />
                                        <div class="response-tasks-container" v-if="selectedItem.tasks && selectedItem.tasks.length">
                                            <h3 class="tincx-h5">{{ $t('jobs.your_tasks') }}</h3>
                                            <ul class="advantages response-tasks mb-4">
                                                <li v-for="task in selectedItem.tasks">{{ task }}</li>
                                            </ul>
                                        </div>

                                        <ContactButton v-if="buttonConfig" :config="buttonConfig" />

                                        <hr />
                                        <div
                                            class="response-advantages-container"
                                            v-if="selectedItem.advantages && selectedItem.advantages.length"
                                        >
                                            <h3 class="tincx-h5">{{ $t('jobs.benefits') }}</h3>
                                            <ul class="advantages response-advantages" :class="{ hidden: !advantagesReadMore }">
                                                <li v-for="advantage in selectedItem.advantages.slice(0, advantagesReadMore ? selectedItem.advantages.length : 6)">{{ advantage }}</li>
                                            </ul>

                                            <a
                                                href="#"
                                                v-if="selectedItem.advantages.length > 6"
                                                @click.prevent="advantagesReadMore = !advantagesReadMore"
                                                class="text-grey show-more-advantages"
                                            >
                                                <span class="more" v-if="!advantagesReadMore">{{ $t('generic.read_more' )}}</span>
                                                <span class="less" v-else>{{ $t('generic.read_less' )}}</span>
                                            </a>

                                            <p class="text-grey advantages-info d-md-block">
                                               <small class="text-grey">{{ $t('jobs.benefits_info') }} </small>                                               
                                            </p>


                                        </div>

                                        <p><strong class="mt-3 d-block">{{ $t('generic.share_ad') }}</strong></p>
                                        <div class="row-small align-items-center mt-2 social-share">
                                            <a href="#" @click.prevent="share('whatsapp')" class="whatsapp-link col-auto">
                                                <img src="/images/icons/icon-whatsapp.svg" alt="Whatsapp" />
                                            </a>
                                            <a href="#" @click.prevent="share('facebook')" class="facebook-link col-auto">
                                                <img src="/images/icons/icon-facebook.svg" alt="Facebook" />
                                            </a>
                                            <a href="#" @click.prevent="share('email')" class="email-link col-auto">
                                                <img src="/images/icons/icon-email.svg" alt="Email" />
                                            </a>
                                            <a href="#" @click.prevent="share('copy')" class="copy-link col-auto position-relative">
                                                <img src="/images/icons/icon-link.svg" alt="Link" />
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="infos d-none d-lg-block" id="info-desktop" v-if="selectedItem && selectedItem.customer">
                                    <JobTitleInfo :selectedItem="selectedItem" />
                                </div>

                                <div id="other" v-if="selectedItem && selectedItem.customer">
                                    <div class="response-gehalt-container" v-if="false && selectedItem.user_type!='private'">
                                        <hr />
                                        <div class="row justify-content-between align-items-end">
                                            <div class="col">
                                                <h3 class="mt-0 tincx-h5">{{ $t('fe.category.job.salary.title') }}</h3>
                                                <p>
                                                    {{ $t('fe.category.job.salary.subtitle') }}
                                                </p>
                                                <a href="#" class="btn btn-small btn-white btn-be mt-1">{{ $t('fe.category.job.salary.button_show') }} </a>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-12 col-lg-5 mt-3 mt-lg-0 text-right">
                                                <img class="w-100" src="/images/jobs-geld.svg" />
                                                <div class="d-flex justify-content-between">
                                                    <small class="mt-1">{{ $t('generic.min') }} €</small>
                                                    <small class="mt-1">{{ $t('generic.max') }} €</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div 
                                        class="response-media-container mb-4" 
                                        v-if="selectedItem.customer.gallery || selectedItem.customer.video">
                                        <hr />
                                        <h3 class="tincx-h5 mt-0">{{ $t('jobs.insights')}}</h3>

                                        <Slick
                                            :key="selectedItem.id"
                                            v-if="selectedItem.customer.gallery"
                                            ref="slickRef"
                                            class="gallery slick row-small response-images"
                                            :options="slickOptions">
                                            <a 
                                                v-for="image in selectedItem.customer.gallery"
                                                :href="image"
                                                data-type="image"
                                                data-fancybox="gallery">
                                                <img :src="image" />
                                            </a>
                                        </Slick>

                                        <div v-if="selectedItem.customer.video" class="response-video-container" >
                                            <video
                                                class="w-100 video-container response-video mt-4"
                                                controls=""
                                                loop=""
                                                playsinline=""
                                                autoplay=""
                                                preload="auto"
                                            >
                                                <source :src="selectedItem.customer.video" type="video/mp4" />
                                            </video>
                                        </div>
                                    </div>
                                    <div class="response-agency-benefits-container" v-if="false && selectedItem.user_type!='private'">
                                        <hr />
                                        <h3 class="tincx-h5 mt-0">{{ $t('jobs.getting_to_know_the_company')}}</h3>
                                        <div>
                                            <div class="row-small benefits">
                                                <div class="col-12 col-lg-6 col-xl-3 benefit">
                                                    <a
                                                        href="https://dm.tincx.it/images/demo-video.mp4"
                                                        target="_blank"
                                                        class="d-block bg-primary-light text-center"
                                                    >
                                                        <div class="icon-box"><img src="/images/icons/icon-benefit-video.svg" /></div>
                                                        <p class="tincx-h6 mt-1 mt-lg-2 mb-0">{{ $t('fe.category.job.presentation_video') }}</p>
                                                    </a>
                                                </div>
                                                <div class="col-12 col-lg-6 col-xl-3 benefit mt-3 mt-lg-0">
                                                    <!-- TODO: add to JS -->
                                                    <div class="bg-light text-center">
                                                        <div class="icon-box"><img src="/images/icons/icon-benefit-locked.svg" /></div>
                                                        <p class="tincx-h6 mt-1 mt-lg-2 mb-0">Benefit 2</p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-6 col-xl-3 benefit mt-3 mt-xl-0">
                                                    <div class="bg-light text-center">
                                                        <div class="icon-box"><img src="/images/icons/icon-benefit-locked.svg" /></div>
                                                        <p class="tincx-h6 mt-1 mt-lg-2 mb-0">Benefit 3</p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-6 col-xl-3 benefit mt-3 mt-xl-0">
                                                    <div class="bg-light text-center">
                                                        <div class="icon-box"><img src="/images/icons/icon-benefit-locked.svg" /></div>
                                                        <p class="tincx-h6 mt-1 mt-lg-2 mb-0">Benefit 4</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="response-tags-container" v-if="selectedItem.customer.tags && selectedItem.customer.tags.length">
                                        <hr />
                                        <h3 class="tincx-h5 mt-0 mb-2">{{ $t('generic.category') }}</h3>
                                        <div class="tag-container row">
                                            <span v-for="tag in selectedItem.customer.tags" class="tag text-small mt-2 response-tags">{{ tag }}</span>
                                        </div>
                                    </div>
                                    <hr class="d-lg-none mb-0" />
                                </div>

                                <div id="info-mobile" class="d-lg-none position-relative mt-3" v-if="selectedItem">
                                    <JobTitleInfo :selectedItem="selectedItem" />                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- main-job end -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import Slick from './inc/Slick.vue'
import emitter from '../plugins/mitt';
import JobTitleContent from './inc/JobTitleContent.vue'
import JobTitleInfo from './inc/JobTitleInfo.vue'
import ContactButton from './inc/ContactButton.vue'

export default {
    name: 'JobList',
    components: {
        Slick,
        JobTitleContent,
        JobTitleInfo,
        ContactButton
    },
    data() {
        return {
            loading: false,
            detailId: null,
            detailIdInitialLoad: null,
            showContactButtons: false,
            activeTab: 'main',
            descriptionReadMore: false,
            advantagesReadMore: false,
            filter: {
                category: null,
                employmentType: null,
                benefit: null,
                sort: 'new',
            },
            offers: null,
            categories: [],
            employmentTypes: [],
            benefits: [],
            slickOptions: {
                slidesToShow: 2,
                slidesToScroll: 2,
                dots: true,
                arrows: false,
                responsive: [
                    {
                        breakpoint: 639,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        },
                    }
                ],
            }
        }
    },

    computed: {
        selectedItem() {
            if (!this.detailId || !this.offers) return null
            return this.offers.find((i) => i.id == this.detailId)
        },
        buttonConfig() {
            if(!this.selectedItem) return null
            let buttons = this.selectedItem.buttons || []
            if(!buttons.length) return null
            return {
                title: this.$t('jobs.apply_now'),
                buttons: buttons
            }
        }
    },

    mounted: function () {
        let parent = document.getElementsByClassName('v-list-component')
        if(parent.length && parent[0].dataset.detailId) {
            this.detailIdInitialLoad = parent[0].dataset.detailId
        }
        this.loadData()
    },

    watch: {
        filter: {
            handler(val) {
                this.loadData()
            },
            deep: true,
        },
        detailId: {
            handler(val) {
                emitter.emit('listing_page_detail', val ? true : false)
            }
        },
    },

    methods: {
        share: async function(method) {
            if(!this.selectedItem) return
            let text = $t('fe.category.job.share_text')
            let shareUrl = `${window.location.protocol}//${window.location.host}${this.selectedItem.url}`
            if(method=='whatsapp') {
                var whatsappUrl = 'https://wa.me/?text=' + encodeURIComponent(text + shareUrl);
                window.open(whatsappUrl, '_blank');
            } else if(method=='facebook') {
                var facebookUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(shareUrl)+'';
                window.open(facebookUrl, '_blank');
            } else if(method=='email') {
                var emailUrl = 'mailto:?subject=dolomitenmarkt.it&body=' + encodeURIComponent(text+shareUrl);
                window.open(emailUrl, '_blank');
            } else if(method=='copy') {
                var $tempInput = $('<input>');
                $('body').append($tempInput);
                $tempInput.val(window.location.href).select();
                document.execCommand('copy');
                $tempInput.remove();
            }
        },
        loadMore: function() {
            if(this.loading) return

            this.loading = true
            axios
                .get('/api/jobs', {
                    params: {
                        filter: this.filter,
                    },
                })
                .then((response) => {
                    this.loading = false
                    this.offers = [...this.offers, ...response.data.offers]
                }).catch((error) => {
                    console.log(error)
                    this.loading = false
                    Swal.fire('Fehler', this.$t('generic.error_loading'), 'error')
                })
        },
        /*onMoreFilterSearch: function() {
            emitter.emit('more_filter_search')
        },*/
        onTab: function(tab) {
            emitter.emit('list_tab_change', tab)
        },
        loadData: function () {
            if(this.loading) return
            this.loading = true
            axios
                .get('/api/jobs', {
                    params: {
                        filter: this.filter,
                        id: this.detailIdInitialLoad
                    },
                })
                .then((response) => {
                    this.loading = false
                    this.offers = response.data.offers
                    
                    this.categories = response.data.categories
                    this.employmentTypes = response.data.employmentTypes
                    this.benefits = response.data.benefits

                    if(this.detailIdInitialLoad) {
                        this.selectItem(this.offers.find(i => i.id==this.detailIdInitialLoad))
                        this.detailIdInitialLoad = null
                        setTimeout(function() {
                            $('html, body').animate({scrollTop: $('#scroll-box').offset().top-150});
                        }, 1000)
                    } else {
                        if ($(window).width() >= 768) {
                            this.$nextTick(() => {
                                if(this.offers.length && !this.detailId) {
                                    this.selectItem(this.offers[0])
                                }
                            });
                        }
                    }
                })
                .catch((error) => {
                    this.loading = false
                    console.log(error)
                    Swal.fire('Fehler', this.$t('generic.error_loading'), 'error')
                })
        },

        selectItem: function (item) {
            this.descriptionReadMore = false
            this.advantagesReadMore = false

            if(item) {
                this.detailId = item.id
                window.history.pushState('job_'+item.id, '', item.url);
            } else {
                //todo: - vermutlich ist dieser link nur responsive angezeigt
                this.detailId = null
                window.history.pushState('job_empty', '', '/jobs/liste');    
            }
        },
    },
}
</script>
