import { defineRule, configure } from 'vee-validate';
import { required, email, min, max, confirmed, numeric } from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';
import de from '@vee-validate/i18n/dist/locale/de.json';
import dot from 'dot-object'
import php_de from '../../../resources/lang/php_de.json';
const validationNames = dot.object({...php_de}).validation.attributes

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);
defineRule('max', max);
defineRule('numeric', numeric);
defineRule('confirmed', confirmed);
configure({
    generateMessage: localize({
        de: {
            messages: de.messages,
            names: validationNames
            /*names: {
                username: 'Benutzername / E-Mail',
                password: 'Password',
                email: 'E-Mail',
                password_confirmation: 'Password-Bestätigung',
                userType: 'Ich bin',
                firstname: 'Vorname',
                lastname: 'Nachname',
                tax: 'Steuernummer',
                street: 'Straße',
                street_number: 'Nummer',
                plz: 'PLZ',
                place: 'Ort',
                country: 'Land'
            }*/
        }        
    }),
  });
localize('de')