<template>
    <div class="large-card px-3 py-md-4 px-xl-4">
        <h4 class="mt-0 mb-2">Deine persönlichen Angaben ({{ firstname }} {{ lastname }})</h4>
        <Form ref="form" novalidate @submit="onSubmit" @invalid-submit="onInvalid" class="form" v-slot="{ errors, values, meta }">
            <div class="row-small">
                <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                    <Field
                        type="text"
                        name="firstname"
                        v-model="firstname"
                        :placeholder="`${$t('validation.attributes.firstname')}*`"
                        rules="required"
                        :class="{ 'is-invalid': errors.firstname }"
                    />
                    <ErrorMessage as="div" name="firstname" class="invalid-feedback" />
                </div>
                <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                    <Field
                        type="text"
                        name="lastname"
                        v-model="lastname"
                        :placeholder="`${$t('validation.attributes.lastname')}*`"
                        rules="required"
                        :class="{ 'is-invalid': errors.lastname }"
                    />
                    <ErrorMessage as="div" name="lastname" class="invalid-feedback" />
                </div>
                <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                    <input type="email" name="email" placeholder="E-Mail" />
                </div>
                <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                    <input type="text" name="phone" placeholder="Telefon" />
                </div>
                <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                    <input type="text" name="plz" placeholder="Postleitzahl" />
                </div>
                <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                    <input type="text" name="city" placeholder="Ort" />
                </div>

                <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                    <input type="text" name="birth_year" placeholder="Geburtsjahr" />
                </div>
                <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                    <Field as="select" name="gender" :class="{ 'is-invalid': errors.gender }">
                        <option value="" selected>{{ $t('validation.attributes.gender') }}</option>
                        <option value="male">{{ $t('generic.gender.male') }}</option>
                        <option value="female">{{ $t('generic.gender.female') }}</option>
                        <option value="other">{{ $t('generic.gender.other') }}</option>
                    </Field>
                    <ErrorMessage as="div" name="gender" class="invalid-feedback" />
                </div>
            </div>

            <hr />

            <h4 class="my-0">Passwort ändern</h4>
            <a href="#" @click.prevent="changePassword" class="btn btn-general btn-bs mt-3">Passwort ändern</a>
            <hr />

            <h4 class="my-0">Wie möchtest du kontaktiert werden?</h4>
            <p class="text-muted">
                Wählen aus, welche Kontaktmöglichkeiten du potenziellen Arbeitgebern und Interessenten anbieten möchtest.<br />
                Es können auch mehrere ausgewählt werden.
            </p>
            <label class="d-flex align-items-center mt-2 mt-sm-1">
                <input type="checkbox" name="contact_email" checked required /><span>E-Mail*</span>
            </label>
            <label class="d-flex align-items-center mt-2 mt-sm-1">
                <input type="checkbox" name="contact_email" checked /><span>Kontaktformular</span>
            </label>
            <label class="d-flex align-items-center mt-2 mt-sm-1">
                <input type="checkbox" name="contact_phone" checked /><span>Telefon</span>
            </label>

            <button
                :class="{ disabled: Object.keys(errors).length || !meta.valid }"
                class="mt-3 ms-lg-auto d-flex align-items-center btn btn-large btn-general btn-te px-3"
            >
                Änderungen speichern
                <svg class="ms-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.8 20.8">
                    <path
                        fill="#FFFFFF"
                        d="M10.4,0C4.7,0,0,4.7,0,10.4s4.7,10.4,10.4,10.4s10.4-4.7,10.4-10.4v0C20.8,4.7,16.1,0,10.4,0z M10.4,18.8
    C5.8,18.8,2,15,2,10.4C2,5.8,5.8,2,10.4,2s8.4,3.8,8.4,8.4C18.8,15,15,18.8,10.4,18.8z"
                    ></path>
                    <path
                        fill="#FFFFFF"
                        d="M15.2,9.7C15.2,9.6,15.2,9.6,15.2,9.7l-3.1-3.1c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l1.4,1.4H6.2
    c-0.6,0-1,0.4-1,1s0.4,1,1,1h6l-1.4,1.4c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l3-3c0,0,0,0,0-0.1
    c0.2-0.2,0.3-0.4,0.3-0.7C15.6,10.1,15.5,9.8,15.2,9.7z"
                    ></path>
                </svg>
            </button>
        </Form>
        <PopupChangePassword :show="popupChangePassword" @close="popupChangePassword = false" />
    </div>
</template>
<script>
import axios from 'axios'
import emitter from '../plugins/mitt'
import Swal from 'sweetalert2'
import { Form, Field, ErrorMessage } from 'vee-validate'
import PopupChangePassword from "@/components/inc/PopupChangePassword.vue"

export default {
    name: 'Profile',
    components: {
        PopupChangePassword,
        Form,
        Field,
        ErrorMessage,
    },
    inject: ['$t'],
    mounted: function () {
        this.loadData()
    },
    watch: {
        'popupChangePassword' : function(val) {
            emitter.emit('popup', val ? 'change_password' : false)
        }
    },
    methods: {
        onInvalid: function ({ values, errors, results }) {
            let html =
                '<ul>' +
                Object.values(errors)
                    .map((e) => '<li>' + e + '</li>')
                    .join('') +
                '</ul>'
            Swal.fire({
                title: this.$t('generic.error'),
                html: html,
                icon: 'error',
            })
        },
        changePassword: function () {
            //emitter.emit('popup', 'change_password')
            this.popupChangePassword = true
        },

        onSubmit: function (values) {
            Swal.fire({
                title: this.$t('popup.register.title'),
                text: this.$t('generic.loading'),
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            })

            axios
                .post('/api/profile', { ...values })
                .then((response) => {
                    console.log(response)
                    if (response.data.error) {
                        Swal.fire(this.$t('generic.error'), response.data.error, 'error')
                        return
                    }

                    Swal.close()
                    Swal.fire({
                        icon: 'success',
                        title: 'Profil speicher',
                        text: 'Erfolgreich gespeichert!'
                    })
                    //this.successMessage = response.data.message
                })
                .catch((error) => {
                    console.log(error)
                    if (error.status == 422) {
                        var errors = error.response.data.errors
                        var errorBag = {}
                        for (var field in errors) {
                            errorBag[field] = errors[field].join(', ')
                        }
                        this.$refs.form.setErrors(errorBag)

                        let html =
                            '<ul>' +
                            Object.values(errorBag)
                                .map((e) => '<li>' + e + '</li>')
                                .join('') +
                            '</ul>'
                        Swal.fire({
                            title: this.$t('generic.error'),
                            icon: 'error',
                            html: html,
                        })
                        return
                    }
                    Swal.fire(this.$t('generic.error'), this.$t('generic.error_try_again_later'), 'error')
                })
        },
        loadData: function () {
            if (this.loading) return
            this.loading = true
            axios
                .get('/api/profile')
                .then((response) => {
                    this.loading = false

                    this.data = response.data
                    if(this.$refs && this.$refs.form) {
                        this.$refs.form.setValues(this.data)
                    }
                })
                .catch((error) => {
                    this.loading = false
                    console.log(error)
                    Swal.fire('Fehler', this.$t('generic.error_loading'), 'error')
                })
        },
    },
    data() {
        return {
            data: null,
            popupChangePassword: false,
            firstname: null,
            lastname: null
        }
    },
}
</script>
